@use '../../styles' as *;

.head {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  gap: 16px;

  @include mediumAndBelowLocal {
    justify-content: center;
    align-items: flex-end;
    flex-direction: column-reverse;
  }
}

.registerBtn {
  align-items: center;
  border: 1px solid $color_gainsboro_100;
  border-radius: 20px;
  color: $font_color_b100;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  padding: 0 14px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.7;
  }
}

.title {
  @include font_h1;

  color: $font_color_b100;
  margin-bottom: 36px;
  text-align: center;

  @include smallAndBelowLocal {
    @include font_h2;
  }
}
